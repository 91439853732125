// Set file variable
$filename: 'scss/custom/_header-action.scss';

/*==============================================================================
  @Action - #{$filename}
==============================================================================*/

.action-menu {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 0 -1.5em;
  padding: 0.25em;
  background: $accent;

  .button {
    flex: 1 0 auto;
    margin: 0 1px 0 0;
    padding: 0.5em 1em;
    background: none;
    color: $white;
    @include add-icon-button('left');

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      color: $white;
      background: rgba($black, 0.1);
    }
  }
  .button-menu {
    flex: 0 0 auto;
  }
  .button.text {
    padding: 0;
    margin-right: 1em;
    background: none;
    font-size: 1.2em;

    &:hover {
      background: none;
    }
  }

  a[x-apple-data-detectors] {
    display: none;
  }
}

@include media-min('medium') {
  .action-menu {
    overflow: visible;
    margin: 0;
    padding: 0;
    background: none;

    .button {
      background: rgba($white, 0.1);
      margin-right: 10px;
      padding: 0.75em 1.5em;

      &:hover,
      &:focus {
        background: rgba($white, 0.15);
      }
    }
  }
}
